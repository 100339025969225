<template>
    <div>
        <b-row>
            <b-col cols="12" lg="12">
                <b-card :header="$t('education_info')" class="soft mb-5">
                    <b-row>
                        <b-col cols="12" lg="12">
                            <b-table :empty-filtered-text="$t('no_result')"
                                     :empty-text="$t('no_result')"
                                     bordered
                                     responsive
                                     :items="graduateData.educations"
                                     :fields="educationFields"
                                     show-empty
                                     class="mb-4 table-dropdown no-scrollbar border rounded">
                                <template #cell(buttons)="row">
                                    <b-dropdown variant="link btn-sm" boundary="window" no-caret>
                                        <template #button-content>
                                            <i class="ri-more-fill"></i>
                                        </template>
                                        <div id="dropdownListHead">
                                            <b-dropdown-item @click="$emit('updateClick',['education_info','edit'],row)" v-if="checkPermission('documentrequest_sendebys')">
                                                <i class="ri-edit-box-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('edit')}}
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="deleteEducationInfo(row.id)" v-if="checkPermission('documentrequest_sendebys')">
                                                <i class="ri-delete-bin-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('delete')}}
                                            </b-dropdown-item>
                                        </div>
                                    </b-dropdown>
                                </template>
                                <template #head(buttons)="row">
                                    <b-button variant="primary" @click="$emit('updateClick',['education_info','add'])"><i class="ri-add-line"></i> {{$t('add')}}</b-button>
                                </template>
                            </b-table>
                        </b-col>
                        <b-col cols="12" lg="12" class="mt-5">
                            <div class="label-as-input-div">
                                <label>{{ $t('do_you_think_to_do_master_in_corp') }}</label>
                                <div>{{ graduateData.do_you_think_to_do_master_in_corp }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="12">
                            <b-button class="mt-1" variant="primary" @click="$emit('updateClick',['education_info'])">{{ $t('edit') }}</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// Template

// Components

//Pages

// Services

// Others

export default {
    props: {
        graduateData: {
            type: Object,
            default: {}
        },
    },

    components: {
    },

    data() {
        return {
            educationFields: [
                {
                    key: 'buttons',
                    label: '',
                },
                {
                    key: 'education_status',
                    label: this.$t('education_status'),
                },
                {
                    key: 'university',
                    label: this.$t('school')+' / '+this.$t('university'),
                },
                {
                    key: 'field_program',
                    label: this.$t('field_program'),
                },
                {
                    key: 'graduate_date',
                    label: this.$t('graduate_date'),
                },
                {
                    key: 'diploma_number',
                    label: this.$t('diploma_number'),
                },
                {
                    key: 'diploma_point',
                    label: this.$t('diploma_point'),
                }
            ]
        }
    },
    created() {

    },
    methods: {

    }
}
</script>


<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col md="3">
                    <ValidationProvider name="personal_email" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('personal_email')">
                            <b-form-input v-model="formData.personal_email"
                                          :placeholder="$t('personal_email')"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="graduate_email" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('graduate_email')">
                            <b-form-input v-model="formData.graduate_email"
                                          :placeholder="$t('graduate_email')"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="mobile_number" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('mobile_number')">
                            <b-form-input v-model="formData.mobile_number"
                                          :placeholder="$t('mobile_number')"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="3">
                    <ValidationProvider name="marriage_status" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('marriage_status')">
                            <multi-selectbox v-model="formData.marriage_status"
                                             :multiple="false"
                                             :options="marriageStatusOptions"
                                             :validate-error="errors[0]">
                            </multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="children_available" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('children_available')">
                            <multi-selectbox v-model="formData.children_available"
                                             :multiple="false"
                                             :options="childrenAvailableOptions"
                                             :validate-error="errors[0]">
                            </multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="country" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('country')">
                            <country-selectbox v-model="formData.country"
                                             :validate-error="errors[0]">
                            </country-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="6">
                <b-row class="mb-4">
                        <b-col cols="12" md="6" class="mb">
                            <b-card  :header="$t('residence_address')" class="soft h-100">
                                <ValidationProvider name="current_city" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('current_city')">
                                        <city-selectbox v-model="formData.current_city"
                                            :validate-error="errors[0]"
                                            :disabled="true">
                                         </city-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                                <b-row>
                                    <b-col cols="12" sm="12">
                                        <ValidationProvider name="current_district" rules="" v-slot="{errors}">
                                            <b-form-group :label="$t('current_district')">
                                                <district-outline-selectbox v-model="formData.current_district"
                                                        :city_id="formData.current_city"
                                                        :validate-error="errors[0]"
                                                        :disabled="true">
                                                </district-outline-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col cols="12" sm="12">
                                        <ValidationProvider name="address" rules="" v-slot="{ errors }">
                                            <b-form-group :label="$t('address')">
                                                <b-form-input v-model="formData.address"
                                                        :placeholder="$t('address')"
                                                        :state="errors[0] ? false : null"
                                                        readonly/>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <b-col cols="12" lg="12">
                                    <b-button variant="primary" @click="addressUpdate" v-if="checkPermission('student_updatestudentaddressinformationfromkps')">
                                            {{ $t('mernis_edit') }}
                                    </b-button>                                
                                </b-col>
                            </b-card>
                        </b-col>
                        <b-col cols="12" md="6" class="mb">
                            <b-card :header="$t('communication_address_2')" class="soft h-100">
                                <b-row>
                                    <b-col cols="12" sm="12">
                                        <ValidationProvider name="communication_city" rules="required" v-slot="{ errors }">
                                            <b-form-group :label="$t('communication_city')">
                                                <b-form-input v-model="formData.communication_city"
                                                        :state="errors[0] ? false : null"/>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col cols="12" sm="12">
                                        <ValidationProvider name="communication_district" rules="required" v-slot="{ errors }">
                                            <b-form-group :label="$t('communication_district')">
                                                <b-form-input v-model="formData.communication_district"
                                                        :state="errors[0] ? false : null"/>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col cols="12" sm="12">
                                        <ValidationProvider name="communication_address" rules="required" v-slot="{ errors }">
                                            <b-form-group :label="$t('address')">
                                                <b-form-input v-model="formData.communication_address"
                                                        :state="errors[0] ? false : null"/>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" lg="12" class="d-flex justify-content-end">
                    <b-button variant="primary" @click="saveForm">{{ $t('save') }}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
// Template

// Components
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"
import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox"
import CitySelectbox from "@/components/interactive-fields/CitySelectbox"
import DistrictOutlineSelectbox from "@/components/interactive-fields/DistrictOutlineSelectbox";
import StudentService from "@/services/StudentService";
//Pages

// Services

// Others
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    props: {
        graduateData: {
            type: Object,
            default: {}
        },
        studentProgram: {
                type: Object,
                default: null
            },
    },

    components: {
        ValidationObserver,
        ValidationProvider,

        MultiSelectbox,
        CountrySelectbox,
        CitySelectbox,
        DistrictOutlineSelectbox
    },
    data() {
        return {
            formData: {},
            marriageStatusOptions: [
                {
                    text: 'Evli',
                    value: 'Evli'
                },
                {
                    text: 'Bekar',
                    value: 'Bekar'
                }
            ],
            childrenAvailableOptions: [
                {
                    text: this.$t('available'),
                    value: 'Var'
                },
                {
                    text: this.$t('none'),
                    value: 'Yok'
                }
            ],
            showLoading: false
        }
    },
    created() {
        this.setData()
    },
    methods: {
        setData(){
            this.formData = {
                personal_email: this.graduateData.personal_email,
                graduate_email: this.graduateData.email,
                mobile_number: this.graduateData.mobile_number,
                marriage_status: this.graduateData.marriage_status,
                children_available: this.graduateData.children_available,
                address: this.graduateData.address
            }
        },

        async saveForm(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {
                this.$toast.success(this.$t('successfully_saved'))
                this.$emit('updateSuccess')
            }
        },
        addressUpdate() {
                StudentService.updateContactInfoKps(this.graduateData.student_number).then(response => {
                    this.$toast.success(this.$t('api.'+response.data.message))
                    this.$emit('updatedData', true);
                    this.showLoading = false
                }).catch(e => {
                    this.showErrors(e)
                })
            }
    }
}
</script>

